// Core variables and mixins
@import "bootstrap/variables.less";
@import "bootstrap/mixins.less";
// Reset and dependencies
@import "bootstrap/normalize.less";
@import "bootstrap/print.less";
//@import "bootstrap/glyphicons.less";
// Core CSS
@import "bootstrap/scaffolding.less";
@import "bootstrap/type.less";
@import "bootstrap/code.less";
@import "bootstrap/grid.less";
@import "bootstrap/tables.less";
@import "bootstrap/forms.less";
@import "bootstrap/buttons.less";
// Components
@import "bootstrap/component-animations.less";
@import "bootstrap/dropdowns.less";
//@import "bootstrap/button-groups.less";
@import "bootstrap/input-groups.less";
@import "bootstrap/navs.less";
@import "bootstrap/navbar.less";
@import "bootstrap/breadcrumbs.less";
@import "bootstrap/pagination.less";
@import "bootstrap/pager.less";
@import "bootstrap/labels.less";
@import "bootstrap/badges.less";
//@import "bootstrap/jumbotron.less";
@import "bootstrap/thumbnails.less";
@import "bootstrap/alerts.less";
//@import "bootstrap/progress-bars.less";
@import "bootstrap/media.less";
//@import "bootstrap/list-group.less";
//@import "bootstrap/panels.less";
@import "bootstrap/responsive-embed.less";
@import "bootstrap/wells.less";
@import "bootstrap/close.less";
// Components w/ JavaScript
@import "bootstrap/modals.less";
@import "bootstrap/tooltip.less";
//@import "bootstrap/popovers.less";
//@import "bootstrap/carousel.less";
// Utility classes
@import "bootstrap/utilities.less";
@import "bootstrap/responsive-utilities.less";

@import "fontawesome5/fontawesome.less";
@import "fontawesome5/solid.less";


@fa-font-path: '../../fonts/';

@text-color: #1E1E1E;
@breadcrumb-bg: unset;
@navbar-border-radius: 0;
@navbar-default-link-active-bg: unset;


@bg-top-color: #febf72;
@bg-bottom-color: #ae6a19;
@mobile-menu-zindex: 2000;
@blue-gradient-top: #006ba4;
@blue-gradient-bottom: #034870;


#navigation-top .logo h1, #navigation-top .logo h4 {
  margin: 0;
  padding: 0;
  line-height: 100%;
}

.clear {
  clear: both;
}

@media (min-width: @screen-sm-min) {
  body {
    background-image: linear-gradient(@bg-top-color, @bg-bottom-color);
    background-color: #ae6a19;
  }

  html {
    background-color: #ae6a19;
  }
}

#page {
  background-color: #FFF;
  @media (min-width: @screen-sm-min) {
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.35);
  }
}

.copyright {
  padding: 15px 0 40px 0;
  text-align: center;
  color: @gray-dark;

  a {
    color: inherit;
  }

  a:hover {
    color: #000;
  }
}

#main-container.home .content {
  border: none;
}

#local-menu-sidebar > ul {
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 30px;
}

#local-menu-sidebar {

  @media (min-width: @screen-sm-min) {

    background: none;
    border: 0;

    .navbar-collapse {
      padding: 0;
    }

    ul {
      float: none;
      margin: 0;


      li {
        list-style: none;
        float: none;
      }
    }


    ul.navbar-nav {

      & > li,
      & > li > ul {
        border-top: 1px solid #e4e4e4;
      }

      & > li.active > ul {
        // Show submenu of currently active item
        display: block;
      }

      & > li > ul {
        // Hide non-active menu's
        display: none;
      }

      & > li.current_page_ancestor > ul,
      & > li.current_page_item > ul {
        display: block !important;
      }

      & > li.current_page_ancestor + li,
      & > li.current_page_item + li {
        border-top: none !important;
      }

      & > li.current_page_item,
      & > li.current_page_ancestor {
        border-top: 1px solid #9e9e9e;
        border-bottom: 1px solid #9e9e9e;
        //background: url('../../images/background-local-menu-active.png') repeat-y top right;
      }

      & > li > a {
        display: block;
        padding: 15px 10px;
        font-weight: bold;
        color: #505050;
        text-decoration: none;
        //
        //&:after{
        //  content: '\f138';
        //  float: right;
        //  font-family: "Font Awesome 5 Pro";
        //  vertical-align: middle;
        //  font-weight: 900;
        //  font-size: 150%;
        //  position: relative;
        //  top: 0px;
        //  color: @gray-lighter;
        //}
        //
        //&:hover,
        //&:active,
        //&:focus{
        //  &:after{
        //    color: @gray-light;
        //  }
        //}
      }

      & > li > a:hover {
        background-color: #eeeeee;
      }

      //li a:hover, ul > li.current_page_item a, ul > li.current_page_ancestor a {
      //  background-image: url('../../images/background-local-menu-arrow-active.png');
      //}

      & > li > ul {
        padding: 10px 5px 10px 15px;
      }

      & li ul a {
        background: none !important;
        display: inline;
      }

      & li ul li {
        padding: 3px 0px;
        list-style: square;
        margin-left: 1em;

        &.active a{
          color: @text-color;
        }
      }
    }

    li.active > a {
      background: @gray-lighter;
    }

  }
}


.content {
  padding: 0 15px 30px 15px;
  min-height: 500px;
}

@media (min-width: @screen-sm-min) {
  .left-side {
    border-right: 1px solid #e4e4e4;
  }

  .right-side {
    border-left: 1px solid #e4e4e4;
  }
}


/*
TOP MENU
*/
@media (min-width: @screen-sm-min) {
  #topmenu {
    background-image: linear-gradient(@blue-gradient-top, @blue-gradient-bottom);
    border: 0;

    ul.nav.navbar-nav {
      & > li.active > a {
        // Current page
        background-image: linear-gradient(@bg-top-color, @bg-bottom-color) !important;
      }

      & > li.open > a {
        // Currently opened dropdown for level 0
        background-image: linear-gradient(lighten(@blue-gradient-top, 7%), lighten(@blue-gradient-bottom, 7%));
      }

      & > li > a {
        font-weight: bold;
        color: #FFF;
      }

    }
  }
}

//#topmenu li.current_page_item a, #topmenu li.current-menu-item a, #topmenu li.current-page-ancestor a, #topmenu li.current-post-ancestor a,
//#topmenu li.current_page_item a:hover, #topmenu li.current-menu-item a:hover, #topmenu li.current-page-ancestor a:hover, #topmenu li.current-post-ancestor a:hover {
//  background: #94692f url('../../images/background-menu-active.jpg') repeat-x top left;
//}

/*
#topmenu li a {
  display: block;
  padding: 7px 15px 6px 15px;
  border-right: 1px solid #3b779a;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 10pt;
  color: #FFF;
  text-decoration: none;
}

#topmenu li a:hover, #topmenu ul :hover > a {
  background: #126494 url('../../images/background-menu-hover.jpg') repeat-x top left;
}

#topmenu ul li.current-page-ancestor:hover > a, #topmenu ul li.current-post-ancestor:hover > a {
  background: #94692f url('../../images/background-menu-active.jpg') repeat-x top left;
}
 */

//#topmenu li li li {
//  display: none;
//}
//
//#topmenu ul ul {
//  position: absolute;
//  left: -1000px;
//  background-color: #eee !important;
//}
//
//#topmenu ul ul a {
//  color: #444;
//  background: none !important;
//  text-transform: none;
//
//}
//
//#topmenu ul ul a:hover {
//  background: #fff !important;
//}
//
//
//#topmenu ul ul li {
//  float: none;
//}
//
//#topmenu ul ul li a {
//  border-right: 1px solid #aaa;
//  border-bottom: 1px solid #aaa;
//  border-left: 1px solid #ddd;
//
//}
//
//#topmenu ul li:hover ul, #topmenu ul li.ie_does_hover ul {
//  left: auto;
//  background-position: 0 0;
//}

/*
END TOP MENU
*/
#navigation-top {
  position: relative;

  .logo {
    padding-top: 13px;
    padding-bottom: 6pt;
  }

  .logo img {
    float: left;
  }

  .logo .tagline {
    float: left;
    width: 300px;
    font-size: 10pt;
    padding-left: 15px;
    color: #999;

    @media (min-width: @screen-sm-min) {
      padding-top: 17px;
    }
  }

  .languages {
    position: absolute;
    top: 15px;
    right: 0px;
  }

  .languages li {
    float: right;
    list-style: none;
    margin-left: 25px;

  }

  .phone {

    @media (max-width: @screen-xs-max) {
      display: none;
    }

    position: absolute;
    font-size: 18pt;
    right: 50px;
    top: 42px;

    a {
      //color: @text-color !important;
    }
  }


}

//
//#search {
//  background: #b6b6b6 url('../../images/background-search.jpg') repeat-x top left;
//  width: 214px;
//  position: absolute;
//  right: 10px;
//  top: 86px;
//  padding: 8px;
//}
//
//#search .textbox {
//  border: 1px solid #c3c3c3;
//  background: #ececec url('../../images/background-search-input.jpg') top left repeat-x;
//  color: #666;
//  padding: 3px;
//  width: 172px;
//}
//
//#search .textbox:hover {
//  background-color: #fafafa;
//  background-image: url('../../images/background-search-input-hover.jpg');
//}

#search .button-go {
  float: right;

}

#featured {
  border: 1px solid #d4d4d4;
}

#featured img {
  display: block;
}

.thumb-comments {
  float: left;
}

.post-entry {
  @media (min-width: @screen-sm-min) {
    margin-left: 92px;
  }
  @media (max-width: @screen-xs-max) {
    a.thumb {
      display: block;
      float: left;
      margin-right: 10px;
    }
  }
}


.post-block .meta, .post-title .meta, .comment-meta {
  color: @gray-light;
}

.post-block {
  padding-bottom: 30px;
  border-bottom: 1px solid #d4d4d4;
  margin-top: 13px;
}

.blog-holder .last {
  border-bottom: none;
}

.thumb-comments img {
  border: 1px solid #d4d4d4;

  @media (max-width: @screen-xs-max) {
    float: left;
    margin-right: 10px;
  }
}

#footer {
  background: #f5f5f5;
  padding: 20px 20px 20px 20px;
  border-top: 1px solid #e4e4e4;
  margin-top: 20px;
  margin-left: @grid-gutter-width / -2;
  margin-right: @grid-gutter-width / -2;
}

#footer li {
  list-style: none;
}

#footer p, #footer ul {
  margin-left: 6px;
}

.widget {
  list-style: none;
  margin-bottom: 30px;
}

.widget p {
  margin-left: 6px;
}

.widget li {
  margin-left: 15px;
  list-style: square;
  margin-bottom: 3pt;
}

.widget_spotlight_nav_menu li, .widget_spotlight_nav_menu div, .widget_spotlight_nav_menu ul {
  margin: 0 !important;
  padding: 0 !important;
}

.widget_local_navigation li, .widget_local_navigation div, .widget_local_navigation ul {
  margin: 0 !important;
  padding: 0 !important;
}

.widget_spotlight_nav_menu li, .sidebar .sidebar-subpages li {
  list-style: none !important;
}


.widget_mailchimp_wpml form {
  margin-left: 6px;
}

.widget_mailchimp_wpml input[type=text] {
  width: 245px;
  height: 19px;
  padding: 2px 5px;
  border: 1px solid #e4e4e4;
  background-color: #FFF;
  color: #444;
}

.template-page .sidebar .widget_mailchimp_wpml input[type=text], .template-single .sidebar .widget_mailchimp_wpml input[type=text] {
  width: 175px;
}

.widget_mailchimp_wpml button {
  //background: url('../../images/aw-mailchimp-submit.jpg') top left no-repeat;
  width: 30px;
  height: 25px;
  padding: 0;
  border: 0;
  float: right;
}

.widget_mailchimp_wpml button span {
  display: none;
}

.widget_spotlight_nav_menu li a, .sidebar .sidebar-subpages li a {
  background-image: linear-gradient(@blue-gradient-top, @blue-gradient-bottom);
  display: block;
  margin-bottom: 3px;
  padding: 10px;
  font-size: 10pt;
  color: #FFF;
  font-weight: bold;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    background-image: linear-gradient(lighten(@blue-gradient-top, 7%), lighten(@blue-gradient-bottom, 7%));
  }

  &:hover:active {
    background-image: linear-gradient(lighten(@blue-gradient-top, 15%), lighten(@blue-gradient-bottom, 15%));
  }


  &:after {
    content: '\f138';
    float: right;
    font-family: "Font Awesome 5 Pro";
    vertical-align: middle;
    font-weight: 900;
    font-size: 150%;
    position: relative;
    top: -4px;
  }
}


.widget_tag_cloud div, .widget ul, .widget_rss_instructions .rssaction {
  padding-left: 6px;
}

.widget_tag_cloud a, .widget_tag_cloud a:hover {
  text-decoration: none;
}

.widget_rss_instructions .rssaction img {
  float: left;
}

.widget_rss_instructions .rssaction a.button {
  display: block;
  margin-left: 35px;
  padding-top: 7px;
}

.entry .meta {
  color: #777;
  font-size: 8.5pt;
  margin: 2em 0;
}


#comments {
  margin-top: 3em;
}

.post-title .thumbnail {
  float: left;
  margin-right: 10px;
  border: 1px solid #d4d4d4;
}

.post-title .thumbnail * {
  display: block;
}


.type-post .entry {
  padding-top: 0.5em;
  /*
  border-left:1px solid #d4d4d4;
  padding-left: 10px;
  padding-top:10px;
  margin-left:10px;
  */
}


.post-title h1, .post-title .meta, .comment-meta {
  margin-bottom: 8px;
  /*
  THIS ALLOWED FOR SOME ROOM NEXT TO THE BLOG POST TITLE SO A THUMBNAIL COULD FIT
  margin-left: 95px;
  */
}


.widget {
  /* background: url('../../images/background-widget.jpg') no-repeat top left; */
}


.content .breadcrumb {
  margin-bottom: 1.5em;
}

/*
TEXT STYLES
*/


p {
  margin-bottom: 1em;
  line-height: 160%;
}

a {
  color: @link-color;
}

a:hover {
  text-decoration: underline;
}

.commentslink {
  color: @link-color !important;
  white-space: nowrap;
}

h1 {
  font-size: 17pt;
  font-weight: bold;
  color: #333;
  margin-top: 0.7em;
  margin-bottom: 0.6em;
}

h2 {
  font-size: 15pt;
  font-weight: bold;
  color: @bg-bottom-color;
  margin-top: 1.2em;
  margin-bottom: 0.6em;
  border-bottom: 1px solid @bg-bottom-color;
  padding: 0 0 5px 0;
}

h3 {
  font-size: 14pt;
  font-weight: bold;
  color: #444;
  margin: 0 0 0.6em 0;
}

h4 {
  font-size: 11pt;
  font-weight: bold;
  color: #444;
  margin: 0 0 0.6em 0;
}

h5, h6, h7, h8 {
  color: #777;
  margin: 0 0 0.6em 0;
}


h5.smalltitle, h5.widgettitle {
  font-weight: bold;
  color: #505050;
  border-bottom: 1px solid #e4e4e4;
  padding: 0 0 4px 6px;
  margin: 10px 0 10px 0;
}

h5.widgettitle {
  margin: 10px 0 5px 0;
}

h3.smalltitle {
  font-size: 13pt;
  font-weight: bold;
  color: #505050;
  margin: 0 0 0.6em 0;
  line-height: 130%;
}

.post-entry .meta a, .post-title .meta a, .comment-meta a {
  color: inherit;
}

.alignright {
  float: right;
  margin: 0 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

a img.alignright {
  float: right;
  margin: 0 0 1em 1em;
}

a img.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

ul, ol {
  padding-left: 0;
}

.entry ul, .entry ol {
  margin: 1em 0 1em 1.5em;
}

.entry ul li, .entry ol li {
  margin-bottom: 0.75em;
}

.entry {
  clear: both;
}


/* ---------------------------------------------------- */
/* Comments                                             */

ol.commentlist, ol.commentlist li {
  list-style: none
}

ol.commentlist > li {
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 1em;
  padding-bottom: 1em;
}

.comment-text {
  clear: left;
}

.comment .comment-title {
  padding-top: 3px;
}

.comment .reply a {
  color: @link-color;
}

.comment-author {
  font-size: 20px;
  padding: 0 0 13px
}

.comment-author img {
  border: 1px solid #E4E4E4;
  float: left;
  margin-right: 10px;
}

.comment-author cite, .comment-author .says {

}

.comment-author cite {
  font-style: normal;
  font-weight: bold;
  font-size: 13pt;

}

.comment-author .says {
  font-size: 15px;
  /* text-transform:uppercase; */
}

#respond {
  margin: 25px 0 0 0;
  clear: both;
}

#cancel-comment-reply-link {
  display: block;
  margin: 0 0 10px 0;

}

li.comment {
  border: 1px solid #eee;
  padding: 10px;
}

#blog-wt {
  clear: both;
}


@media print {
  #local-menu-sidebar, .sidebar, #topmenu, #search, .phone, .breadcrumb, .languages, #respond {
    display: none;
  }

  #main-container .content, #wrapper {
    width: 100% !important;
    border: none;
    min-height: 0px;
  }

  body, #inner-wrapper, #footer, .copyright {
    background: none;
  }
}

@media (max-width: @screen-xs-max) {
  header .languages {
    display: none;
  }
}

a.dropdown-toggle:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f107";
  display: inline-block;
  padding-left: 3px;
  vertical-align: middle;
  font-weight: 900;
}


.navbar-input-group {
  @media (min-width: @screen-sm-min) {
    font-size: 0px; /*removes whitespace between button and input*/
    input {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .btn {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-left: 0px;
    }
  }
  @media (max-width: @screen-xs-max) {
    margin-top: 0px;
    margin-bottom: 0px;

    .btn {
      width: 100%;
    }
  }
}

@media (min-width: @screen-sm-min) {
  .navbar-brand {
    display: none;
  }
}

textarea {
  resize: vertical;
}

.sfsi_responsive_icon_item_container{
  height: auto !important;
}

a.external_icon {
  background: unset !important;
  padding-right: unset !important;

  &:after {
    .sfsi_responsive_icons & {
      display: none;
    }

    content: "\f08e";
    font-family: "Font Awesome 5 Pro";
    display: inline-block;
    padding-left: 4px;
    vertical-align: middle;
    font-weight: 900;
    font-size: 70%;
    position: relative;
    top: -1px;
  }
}

@media (max-width: @screen-xs-max) {
  #topmenu {
    position: fixed;
    top: 0;
    right: 0;
    z-index: @mobile-menu-zindex;
    background: none;
    border: 0;

    .navbar-brand {
      display: none;
    }

    &.opening,
    &.open,
    &.closing {
      width: 100%;
      background: #FFF;

      .navbar-brand {
        display: block;
        margin-top: 12px;
      }
    }
  }
}

.navbar-toggle {
  text-align: center;
  background: @btn-default-bg;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, .35);

  .bars {
    display: inline-block;
  }

  .menu-label {
    display: block;
    font-size: 70%;
    color: @navbar-default-toggle-icon-bar-bg;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.menu-overlay {
  display: none;
}

body {
  margin-bottom: 80px;
}

body.menu-opening,
body.menu-open,
body.menu-closing {
  overflow: hidden;

  .menu-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: @mobile-menu-zindex - 1;
    display: block;
    left: 0;
    top: 0;
  }
}

@media (max-width: @screen-xs-max) {
  a {
    /* Prevent super-long links that stretch the page on mobile */
    word-break: break-all;
  }
}

#share-buttons{
  a:hover{
    text-decoration: none;
  }
  img{
    max-width: 32px;
  }
}

@media print{
  #share-buttons{
    display: none;
  }
}